import Layout from "../../hocs/Layout"

const Error404 = () => {
    return(
        <Layout>
            Error404
        </Layout>
    )
}

export default Error404